body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.survey-header {
  display: block;
  margin: auto;
}

@media only screen and (max-width: 830px) {
  .survey-header {
    display: none;
    margin: auto;
  }
}



.App {
  /* text-align: center; */
}

.navbar-brand {
  height: 150px;
}
